import React from 'react'
import Helmet from 'react-helmet'
import Layout from '../components/layout'

import JC_img from '../assets/images/speakers/JC_img.jpg'
import JT_img from '../assets/images/speakers/JT_img.png'
import KB_img from '../assets/images/speakers/KB_img.png'
import KC_img from '../assets/images/speakers/KC_img.jpg'
import RG_img from '../assets/images/speakers/RG_img.jpg'
import ZT_img from '../assets/images/speakers/ZT_img.png'

const Speakers = (props) => (
    <Layout>
        <Helmet>
            <title>2024 CHCSD Speakers</title>
            <meta name="description" content="2024 CHCSD Speakers Page" />
        </Helmet>

        <div id="main" className="alt">

            <section id="one">
                <div className="inner">
                    <header className="major">
                        <h1>2024 Speakers</h1>
                    </header>

                    <h2 id="content"><center>Keynote Speakers</center></h2>
                    <div className="table-wrapper">
                        <table className="alt">
                            <tbody>
                                <tr>
                                    <td>
                                        <section id="RG">
                                            <span className="image left"><img src={RG_img} alt="" />
                                                <p><u><a href="https://www.patriotacademy.com/press/" target="_blank">Website</a></u></p>
                                            </span>
                                            <p><h3>Rick Green</h3></p>
                                            <p>Rick Green is known as America's Constitution Coach. He is a former Texas State Representative, national speaker, author and radio/podcast host. He and his family travel the nation bringing America's forgotten history and heroes to life in fun and entertaining ways, with an emphasis on our moral and constitutional heritage. Rick co-hosts the national daily radio program, WallBuilders Live!, with David Barton. Rick's family also produced <i>Chasing American Legends</i>, an entertaining reality show traveling with comedian Brad Stine and the Green family as they investigate history's mysteries and the American story.</p>
                                            <p>Rick is the author and executive producer of <i>Constitution Alive</i>, America's most engaging and entertaining study of the U.S. Constitution, as well as <i>Biblical Citizenship In Modern America</i>, Biblical instruction for a free people.</p>
                                            <p>Along with his wife, Kara, Rick is the founder of Patriot Academy, an elite leadership training program specializing in applied civics with a Biblical, historical and constitutional foundation. Through their more than 15,000 Constitution Coaches, they are training and empowering adults across the nation to educate their communities about the Constitution.</p>
                                            <p>Rick & Kara have 4 grown children and three grandchildren.</p>
                                        </section>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <section id="ZT">
                                            <span className="image right"><img src={ZT_img} alt="" />
                                                <p><u><a href="https://zantyler.com/about/" target="_blank">Website</a></u></p>
                                            </span>
                                            <p><h3>Zan Tyler</h3></p>
                                            <p>Zan Tyler's homeschool journey began in 1984 when homeschooling was illegal in her state. The State Superintendent of Education threatened Zan with jail for insisting on homeschooling her son. For the next eight years, she and other homeschool families battled for homeschool freedom and the establishment of landmark homeschool legislation in South Carolina. To this day, her legislative success has spearheaded legislation to ensure homeschool freedom in other states. In 1990, she founded the South Carolina Association of Independent Home Schools (SCAIHS) and served as its president for ten years. Her fight for homeschool freedom led her to serve as the National Grassroots Director for ParentalRights.org, to advocate for homeschooling in the media (appearing on NBC's Today Show) and at the nation's capital, and to write profusely about the benefits of homeschooling.</p>
                                            <p>Zan's deepest desire is to encourage parents to raise children who love the Lord, understand their callings in life, and become active citizens who understand the freedom principles undergirding our great American heritage. She is an inspirational speaker and author and a consultant for BJU Press Homeschool and for Home School Legal Defense Association (HSLDA). For sixteen years, Zan worked with Christian publishers to develop homeschool curriculum and resources. In 2015, she was presented with the Chris Klicka Award for her dedication and commitment to home education worldwide. She considers the twenty-one years she and her husband, Joe, spent homeschooling their own three children from kindergarten through high school to be one of the greatest privileges of her life. Zan is the host of the Zan Tyler Podcast, which launched in August 2022 and is sponsored by BJU Press Homeschool. You can join Zan and a special guest each week for real encouragement, engaging stories and practical wisdom for surviving and thriving on the homeschool journey.</p>
                                        </section>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <h2 id="content"><center>Workshop Speakers</center></h2>
                    <div className="table-wrapper">
                        <table className="alt">
                            <tbody>
                                <tr>
                                    <td>
                                        <section id="KB">
                                            <span className="image left"><img src={KB_img} alt="" />
                                                <p><u><a href="https://hslda.org/" target="_blank">Website</a></u></p>
                                            </span>
                                            <p><h3>Kevin Boden</h3></p>
                                            <p>Kevin and his wife, Wendi, have 8 children and have been homeschooling parents for 16 years. They have homeschooled in three countries and six states. Kevin is a graduate of Wheaton College (IL) and received his Juris Doctorate from Seattle University. Kevin served for 13 years in full-time military service and continues his service as a Lt. Colonel in the Alaskan Air National Guard. As HSLDA staff attorney and Director of HSLDA International, Kevin serves as the primary contact for members in 11 states and directs HSLDA's international program, working to expand homeschooling freedom around the globe. He is passionate about the freedom to homeschool, the blessings of being a husband and dad, most things outdoors and spending time with his family.</p>
                                        </section>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <section id="KC">
                                            <span className="image right"><img src={KC_img} alt="" />
                                                <p><u><a href="http://www.hswithconfidence.com/" target="_blank">Website</a></u></p>
                                            </span>
                                            <p><h3>Kris Cox</h3></p>
                                            <p>Kris Cox is a retired homeschooler with a Bachelor of Science in Social Work.  She and her husband Bruce have 17+ years of experience in home educating their four children. She also has 10 years of experience as a homeschool consultant, blogger, writer and speaker.</p>
                                            <p>Kris believes that seeking God for guidance and depending on Him is essential to successful homeschooling. She strongly encourages developing a love for the Lord and a love for learning in your children as you educate them. Kris is inspiring and knowledgeable and has a passion for helping parents succeed in this incredible journey called 'home schooling'.</p>
                                            <p>Kris and her husband have six wonderful grandsons ranging from 2 months up to age 4. In her spare time, she loves spending time with her family and friends, walking, biking and reading.  She is also the author of <i>The Homeschool Life: All-in-One Planner</i>, <i>Homeschooling with Confidence</i> and co-author of <i>Growing the Fruit of the Spirit, A Bible-Based Unit Study</i>.</p>
                                        </section>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <section id="JT">
                                            <span className="image left"><img src={JT_img} alt="" />
                                                <p>No Website</p>
                                            </span>
                                            <p><h3>Joe Tyler</h3></p>
                                            <p>Joe and Zan Tyler began homeschooling in 1984, when the State Superintendent of Education threatened Zan with jail. Since then, Joe has been very involved in homeschooling, both through his own family and the homeschool community at large. In 1990, Joe was a founding board member of the South Carolina Association of Independent Home Schools (SCAIHS) and currently serves as the Chairman of the SCAIHS Board. Joe also serves on the Board for Excelsior Academy, a large homeschool co-op that the Tylers founded. He has served as an elder in his church since 1984 and has taught parenting and marriage Sunday School classes and Bible Studies.</p>
                                            <p>Joe enjoys speaking with Zan at homeschooling conferences both nationally and internationally. Joe is the President of Followers in Fellowship, a 501c3 organization committed to preserving and advancing Christian education by supporting homeschool groups. He spent thirty years in the corporate world as an information systems project manager. He and his wife, Zan, spent 21 years homeschooling their three children from kindergarten through high school. Ty manages small businesses, John is a lawyer with the SC Housing Authority, and Elizabeth is currently an executive producer with a major network in Los Angeles. All three children attended college on a variety of scholarships. The Tylers have also been blessed with two wonderful daughters-in-law, a fabulous son-in-law, and eight grandchildren.</p>
                                        </section>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <section id="JC">
                                            <span className="image right"><img src={JC_img} alt="" />
                                            </span>
                                            <p><h3>Julie Christian</h3></p>
                                            <p>Julie Christian, owner of Keys To Learning, is a homeschool mom of 20+ years who also has a professional background in special education. Julie has navigated dyslexia as a homeschool parent and offers testing and consultation.</p>
                                        </section>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <center>
                        <p>Interested in being a speaker at an upcoming conference? Our speaker coordinator would like to hear from you. Send your request to <a href="mailto://speakers@chcsd.org">speakers@chcsd.org</a> by April.</p>
                    </center>
                </div>
            </section>
        </div>
    </Layout>
)

export default Speakers
